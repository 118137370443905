<template>
  <div class="box" style="height: calc(100vh - 110px);">
    <div class=" p-5 ">
      <div class="flex py-3 mb-4">
        <i :class="`pi pi-angle-left my-auto cursor-pointer mx-2 my-auto`" style="font-size: 1.4rem" @click="router.back()"></i>
        <i :class="`pi pi-align-right mx-2 my-auto`" style="font-size: 1rem" ></i>
        <b class="my-auto">MAESTRO DE CÓDIGOS</b>
        <Button
          icon="pi pi-plus"
          label="Nuevo Código"
          class="ml-auto"
          v-tooltip.top="{ value: 'Agregar Código' }"
          v-if="$can('pharmasan.gestion.calidad.mapa-procesos.administrador')"
          @click="displayAddCod = true, blockCod = false"
        />
      </div>
      <DataTable v-model:expandedRows="expandedRows" :value="listGrupos.rows" class="p-datatable-sm" :rowHover="true"  showGridlines responsiveLayout="scroll" @rowExpand="listarCodigos($event)">
        <Column expander :style="{'width': '3rem'}" />
          <Column header="Nombre" style="min-width:130px">
            <template #body="{data}">
              <p style="overflow-wrap: break-word">{{ data.codigo }}</p>
            </template>
          </Column>
          <Column header="Consecutivo" style="min-width:30px">
            <template #body="{data}">
              <p style="overflow-wrap: break-word">{{ data.consecutivo }}</p>
            </template>
          </Column>
          <Column header="Obsoletos" style="min-width:30px">
            <template #body="{data}">
              <p style="overflow-wrap: break-word">{{ data.obsoletos }}</p>
            </template>
          </Column>
          <Column header="Accion" style="text-align: center" headerStyle="width: 15rem;">
            <template #body="{ data }">
              <Button
                icon="pi pi-plus"
                label="Nuevo Doc"
                class="p-button-rounded p-button-success py-1 px-2 p-mr-2"
                @click="addCodigo(data)"
                v-tooltip.top="'Agregar documento'"
              />
            </template>
          </Column>
          <template #expansion="{ data }">
            <div>
              <div v-if="'detalle' in data">
                <DataTable :value="data.detalle.rows" class="p-datatable-sm" :rowHover="true"  showGridlines responsiveLayout="scroll">
                  <Column header="Nombre" style="min-width:130px">
                    <template #body="{data}">
                      <p style="overflow-wrap: break-word">{{ data.codigo }}</p>
                    </template>
                  </Column>
                  <Column header="Consecutivo" style="min-width:180px">
                      <template #body="{data}">
                      <p style="overflow-wrap: break-word">{{data.consecutivo}}</p>
                      </template>
                  </Column>
                  <Column header="Documento" style="min-width:180px">
                    <template #body="{data}">
                      <p style="overflow-wrap: break-word">{{ data.mapa ? data.mapa.nombre : ''}}</p>
                    </template>
                  </Column>
                  <Column header="Estado" >
                    <template #body="{data}">
                      <span v-if="!data.mapa.padre" class="bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded bg-green-700">Disponible</span>
                    </template>
                  </Column>
                </DataTable>
                <Paginator v-model:first="offset"
                  :rows="limit"
                  :totalRecords="parseInt(data.detalle.count)"
                  :rowsPerPageOptions="[10,20,30,100]"
                  @page="onPage($event)"
                />
              </div>
            </div>
          </template>
      </DataTable >
      <Paginator v-model:first="offsetG"
            :rows="limitG"
            :totalRecords="parseInt(listGrupos.count)"
            :rowsPerPageOptions="[10,20,30,100]"
            @page="onPageG($event)"
          />
    </div>
    <!-- BEGIN: nuevo cod -->
    <Dialog v-model:visible="displayAddCod" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
      <template v-slot:header>
            <div class="w-full">
                <div class="flex justify-center">
                  <FilePlusIcon class="block mr-2 h-5" />
                  <b>Agregar Código</b>
                </div>
            </div>
        </template>
        <form action="" @submit.prevent="onSubmit">
          <div class="mb-5">
              <label class="mb-2">Código</label>
              <InputText :disabled="blockCod" v-model="newCod.codigo" type="text" class="rounded-md w-full" />
          </div>
          <div class="mb-5">
              <label class="mb-2">Nombre</label>
              <InputText v-model="newCod.nombre" type="text" class="rounded-md w-full" />
          </div>
          <div class="flex justify-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-outlined" @click="cerrarAddCod" />
            <Button label="Crear" icon="pi pi-check" class="ml-2" autofocus @click="onSubmit" />
          </div>
        </form>
    </Dialog>
    <!-- END: nuevo cod -->
  </div>
</template>
<script>

import { onMounted, ref, watch } from 'vue'
import CodigosServices from './services/codigos.service'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import { string, object } from 'yup'
import { useToast } from 'primevue/usetoast'
export default {
  name: 'CalidadCodigos',
  setup () {
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const _codigosServices = ref(new CodigosServices())
    const limit = ref(10)
    const offset = ref(0)
    const limitG = ref(10)
    const offsetG = ref(0)
    const listGrupos = ref([])
    const listCodigos = ref([])
    const filePath = ref('')
    const codigos = ref([])
    const proceso = ref({})
    const addCarpeta = ref(false)
    const newCarpeta = ref('')
    const editarFolder = ref(0)
    const addDocumento = ref(false)
    const expandedRows = ref([])
    // const newCod = ref({})
    const selectCod = ref('')
    const displayAddCod = ref(false)
    const blockCod = ref(false)
    const displayViewDoc = ref(false)
    const tipoView = ref('pdf')
    const filtros = ref()

    const validationSchema = object().shape({
      nombre: string().required().label('El nombre es requerido'),
      codigo: string().required().label('El codigo es requerido')
    })

    const { values: newCod, errors, handleSubmit, handleReset } = useForm({
      validationSchema
    })

    const onSubmit = handleSubmit((values) => {
      if (Object.values(newCod).some(a => !a)) {
        alertar('Faltan campos por llenar')
      } else {
        crearCodigo()
      }
    })

    const alertar = (mensaje, tipo) => {
      const icon = tipo === 1 ? 'warning' : 'warning'
      Swal.fire({
        icon: icon,
        title: ` <span style="line-height: normal;">${mensaje}</span>`
      })
    }

    useField('nombre', null, {
      initialValue: ''
    })
    useField('codigo', null, {
      initialValue: ''
    })

    const listarGrupos = () => {
      const object = {
        ...filtros.value,
        limit: limitG.value,
        offset: offsetG.value
      }
      _codigosServices.value.getCodigosGrupo(object).then(({ data }) => {
        listGrupos.value = data
      }).catch(err => {
      console.log(err.message)
      })
    }

    const listarCodigos = (event) => {
      const object = {
        ...filtros.value,
        limit: limit.value,
        offset: offset.value
      }
      selectCod.value = event.data.codigo
      _codigosServices.value.getCodigos(object, selectCod.value).then(({ data }) => {
        event.data.detalle = data
      }).catch(err => {
      console.log(err.message)
      })
    }
    const onPageG = ({ first, page, pageCount, rows }) => {
      const object = {
        ...filtros.value,
        limitG: rows,
        offsetG: first
      }
      _codigosServices.value.getCodigosGrupo(object).then(({ data }) => {
        listGrupos.value = data
      }).catch(err => {
      console.log(err.message)
      })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      const object = {
        ...filtros.value,
        limit: rows,
        offset: first
      }
      _codigosServices.value.getCodigos(object, selectCod.value).then(({ data }) => {
          listCodigos.value = data
      }).catch(err => {
      console.log(err.message)
      })
    }

    const crearCodigo = () => {
      const saveData = {
        codigo: newCod.codigo.toUpperCase(),
        nombre: newCod.nombre.toUpperCase()
      }
      _codigosServices.value.crearCodigo(saveData).then(({ data }) => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se creó el código correctamente', life: 3000 })
        displayAddCod.value = false
        expandedRows.value = []
        handleReset()
      }).catch(err => {
      console.log(err.message)
      })
    }

    const addCodigo = (data) => {
      newCod.codigo = data.codigo
      displayAddCod.value = true
      blockCod.value = true
    }

    const cerrarAddCod = () => {
      displayAddCod.value = false
      handleReset()
    }

    onMounted(() => {
      listarGrupos()
    })
    watch(displayAddCod, (status) => {
      if (!status) handleReset()
    })
    return {
      expandedRows,
      errors,
      listGrupos,
      listCodigos,
      filePath,
      router,
      route,
      limit,
      offset,
      limitG,
      offsetG,
      filtros,
      codigos,
      proceso,
      addCarpeta,
      newCarpeta,
      editarFolder,
      addDocumento,
      newCod,
      displayAddCod,
      blockCod,
      displayViewDoc,
      tipoView,
      addCodigo,
      listarCodigos,
      cerrarAddCod,
      onPageG,
      onPage,
      onSubmit,
      crearCodigo
    }
  }
}
</script>
<style lang="scss">
.bg-grad{
  background: transparent linear-gradient(1deg, #0080F9 0%, #00DCB1 100%) 0% 0% no-repeat padding-box;
}
.w-proceso{
  width: 30%;
}
</style>
